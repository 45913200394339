import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';
import { TooltipPosition, TooltipSize } from '@design/overlays/tooltip/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { AsyncPipe, NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { UserService } from '@core/services/user.service';
import { SignalrService } from '@core/services/signalr.service';
import { UserPermissions } from '@core/services/permission.service';
import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { NavCounterChipComponent } from '../../../../stories/chips/nav-counter-chip/nav-counter-chip.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    AssetSrcDirective,
    FocusableDirective,
    NgClass,
    TranslateModule,
    AsyncPipe,
    ButtonComponent,
    TooltipDirective,
    RouterLinkActive,
    NavCounterChipComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent {
  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;
  protected readonly TooltipPosition = TooltipPosition;
  protected readonly TooltipPadding = TooltipSize;
  protected readonly CONVERSATIONS_BASE_URL = CONVERSATIONS_BASE_URL;

  private readonly userService = inject(UserService);
  private readonly signalR = inject(SignalrService);

  get unreadMessages(): number {
    return this.signalR.totalUnreadMessages;
  }

  get incompleteTasks(): number {
    return this.signalR.totalIncompleteTasks;
  }

  get permissions(): UserPermissions {
    return this.userService.permissions;
  }
}
