import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'cc-nav-counter-chip',
  standalone: true,
  imports: [],
  templateUrl: './nav-counter-chip.component.html',
  styleUrl: './nav-counter-chip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCounterChipComponent {
  count = input.required<number>();
  limitTo = input<'9' | '99' | '999' | undefined>(undefined);

  protected readonly countText = computed(() => {
    const count = this.count();

    if (count > 9 && this.limitTo() === '9') return '9+';
    if (count > 99 && this.limitTo() === '99') return '99+';
    if (count > 999 && this.limitTo() === '999') return '999+';
    return count.toString();
  });
}
