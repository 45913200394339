import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appRoutes } from '@clover/app.routes';
import { AuthModule } from '@clover/auth/auth.module';
import { CustomRouterStateSerializer } from '@clover/custom-router-state-serializer';
import { CoreModule } from '@core/core.module';
import { DOCUMENT, NAVIGATOR, WINDOW } from '@core/helpers/global-objects';
import { ConfigService } from '@core/services/config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { RouterStateSerializer, withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { provideStore } from '@ngxs/store';
import { environment } from 'environments/environment';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideScrollbarOptions } from 'ngx-scrollbar';
import { v4 as uuidv4 } from 'uuid';
import { AppComponent } from './app/app.component';

function appInitializerFactory(appConfig: ConfigService) {
  return async (): Promise<void> => {
    await appConfig.load();
  };
}

function translationsLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', `.json?v=${uuidv4()}`);
}

bootstrapApplication(AppComponent, {
  providers: [
    // Core
    provideRouter(
      appRoutes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [ConfigService],
      multi: true,
    },
    // {
    //   provide: ErrorHandler,
    //   useClass: CustomErrorHandler,
    // },
    importProvidersFrom(CoreModule, AuthModule),

    // Store
    provideStore(
      [],
      {
        selectorOptions: {
          suppressErrors: true,
        },
      },
      withNgxsRouterPlugin(),
      withNgxsReduxDevtoolsPlugin({
        disabled: environment.production,
      }),
    ),
    importProvidersFrom(NgxsActionsExecutingModule.forRoot()),
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },

    // Global objects
    { provide: DOCUMENT, useValue: document },
    { provide: WINDOW, useValue: window },
    { provide: NAVIGATOR, useValue: navigator },

    // Translations
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translationsLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),

    // Miscellaneous
    provideEnvironmentNgxMask(),
    provideScrollbarOptions({
      visibility: 'hover',
      appearance: 'compact',
    }),

    // "ccAutoAnimate" directive workaround
    // By default Angular removes styles of the component from the DOM when it is destroyed.
    // Because of this, elements that use the ccAutoAnimate directive lose their styles for a moment when playing the "leave" animation.
    {
      provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
      useValue: false,
    },
  ],
}).catch((error) => {
  console.error(error);
});
