import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageWithSubnavComponent } from '@design/layout/page-with-subnav/page-with-subnav.component';
import { Store } from '@ngxs/store';
import { ClearProductState, LoadProduct, LoadWorklists } from './state/products.actions';

@Component({
  selector: 'cc-products',
  standalone: true,
  imports: [RouterOutlet, PageWithSubnavComponent],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store)

  ngOnInit() {
    this.store.dispatch(new LoadProduct({ query: '' }));
    this.store.dispatch(new LoadWorklists());
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearProductState());
  }
}
