import { type Routes } from '@angular/router';
import { productsRoutes } from '@clover/product-v2/products.routes';
import { CONVERSATIONS_BASE_URL, conversationsPageRoutes } from '@conversations/routes';
import { AuthGuard } from '@core/guards/auth.guard';
import { AppRoutes } from '@core/models/routes';
import { NetworkV2Redirector } from '@network/network-v2-redirector.guard';
import { CAMPAIGNS_BASE_URL, campaignsPageRoutes } from './campaigns-v2/routes';
import { dashboardRoutes } from './dashboard-v2/routes';
import { OopsPageComponent } from './error-pages/oops-page/oops-page.component';
import { TASKS_BASE_URL, tasksPageRoutes } from './tasks-v2/routes';
import { WORKFLOWS_BASE_URL, workflowsPageRoutes } from './workflows-v2/routes';

export const appRoutes: Routes = [
  {
    path: AppRoutes.Dashboard,
    loadChildren: async () => await import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard-v2',
    children: dashboardRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Network,
    loadChildren: async () => await import('./network/network.module').then((mod) => mod.NetworkModule),
    canActivate: [AuthGuard, NetworkV2Redirector],
  },
  {
    path: AppRoutes.NetworkV2,
    loadChildren: async () => await import('@clover/network-v2/network-v2.module').then((mod) => mod.NetworkV2Module),
    canActivate: [AuthGuard, NetworkV2Redirector],
  },
  {
    path: AppRoutes.Tasks,
    loadChildren: async () => await import('./tasks/tasks.module').then((mod) => mod.TasksModule),
    canActivate: [AuthGuard],
  },
  {
    path: TASKS_BASE_URL,
    children: tasksPageRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Files,
    loadChildren: async () => await import('./files/files.module').then((mod) => mod.FilesModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Settings,
    loadChildren: async () => await import('./settings/settings.module').then((mod) => mod.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    // New campaigns routes
    path: CAMPAIGNS_BASE_URL,
    children: campaignsPageRoutes,
    canActivate: [AuthGuard],
  },
  {
    // Legacy campaigns routes
    path: AppRoutes.Campaigns,
    loadChildren: async () => await import('./campaigns/campaigns.module').then((mod) => mod.CampaignsModule),
    canActivate: [AuthGuard],
  },
  {
    // New workflows routes
    path: WORKFLOWS_BASE_URL,
    children: workflowsPageRoutes,
    canActivate: [AuthGuard],
  },
  {
    // Legacy workflows routes
    path: AppRoutes.Workflows,
    loadChildren: async () => await import('./workflows/workflows.module').then((mod) => mod.WorkflowsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren: async () =>
      await import('./advanced-search/advanced-search.module').then((mod) => mod.AdvancedSearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'conversations',
    loadChildren: async () =>
      await import('./conversations/conversations.module').then((mod) => mod.ConversationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: CONVERSATIONS_BASE_URL,
    children: conversationsPageRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'companyMatching',
    loadChildren: async () =>
      await import('./company-matching/company-matching.module').then((mod) => mod.CompanyMatchingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'companyApproval',
    loadChildren: async () =>
      await import('./company-approval/company-approval.module').then((mod) => mod.CompanyApprovalModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Products,
    loadChildren: async () => await import('./products/products.module').then((mod) => mod.ProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'products-v2',
    children: productsRoutes,
    canActivate: [AuthGuard],
  },
  {
    path: 'reporting',
    loadChildren: async () => await import('./reporting/reporting.module').then((mod) => mod.ReportingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'companyAccessRequests',
    loadChildren: async () =>
      await import('./company-access-requests/company-access-requests.module').then(
        (mod) => mod.CompanyAccessRequestsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'task-info',
    loadChildren: async () => await import('./task-info/task-info.module').then((mod) => mod.TaskInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bpi',
    loadChildren: () => import('./bpi/bpi.module').then((mod) => mod.BpiModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: OopsPageComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
