import { inject, InjectionToken, Provider } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutes } from '@core/models/routes';

export const GLOBAL_SEARCH_ROUTER_TOKEN = new InjectionToken<Observable<string>>('GLOBAL_SEARCH_ROUTER_TOKEN');

export const globalSearchProvider: Provider = {
  provide: GLOBAL_SEARCH_ROUTER_TOKEN,
  useFactory: () => {
    const router = inject(Router);
    return router.events.pipe(
      filter((events) => events instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url.split('/')[1]),
      map((url: string) => {
        return globalSearchMap.get(url as AppRoutes);
      }),
    );
  },
};

export const globalSearchMap = new Map<AppRoutes, string>([
  [AppRoutes.Dashboard, 'Company'],
  [AppRoutes.NetworkV2, 'Company'],
  [AppRoutes.Products, 'Products'],
  [AppRoutes.Tasks, 'Tasks'],
  [AppRoutes.Conversations, 'Conversations'],
  [AppRoutes.ConversationsBase, 'Conversations'],
]);
