import { Routes } from '@angular/router';
import { ProductsComponent } from '@clover/product-v2/products.component';
import { provideStates } from '@ngxs/store';
import { ProductsState } from './state/products.state';
import { ConnectedCompaniesState } from '@clover/product-v2/state/connected-companies/connected-companies.state';

export const PRODUCT_BASE_URL = 'products-v2';

export const productsRoutes: Routes = [
  {
    path: '',
    providers: [provideStates([ProductsState, ConnectedCompaniesState])],
    component: ProductsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        loadComponent: () => import('./list/list.component').then((m) => m.ListComponent),
        children: [
          {
            path: 'supplier-association',
            outlet: 'assignTask',
            loadComponent: () =>
              import('./assignTask/supplier-association/supplier-association.component').then(
                (m) => m.SupplierAssociationComponent,
              ),
          },
          {
            path: 'connected-companies',
            outlet: 'assignTask',
            loadComponent: () =>
              import('./assignTask/supplier-association/connected-companies/connected-companies.component').then(
                (m) => m.ConnectedCompaniesComponent,
              ),
          },
        ],
      },
    ],
  },
];
